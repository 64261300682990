import React from "react";
import Link from "next/link";
import Image from "next/image";
export default function Logo() {
  return (
    <Link
      href="/"
      className={`relative md:w-[273px] md:h-[95px] w-[200px] h-[70px] flex shrink-0 justify-start items-start object-contain text-left overflow-hidden`}
    >
      <Image
        src="/logo.webp"
        alt="People’s Courage Internationa"
        fill
        priority={true}
        className="w-full h-full object-contain object-left"
        sizes="(max-width: 768px) 30vw, (max-width: 1200px) 100vw, 30vw"
      />
    </Link>
  );
}
