import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
export default function HeaderMenu(props) {
  const data = props.data;
  const router = useRouter();
  return (
    <nav
      className={`headerMenu flex w-auto gap-12 relative items-center mr-0 ${props.className}`}
    >
      {data?.map((item) => (
        <div
          className={`menuItem group text-dark font-normal h-fit ${
            item.subMenu ? "relative cursor-default" : "cursor-pointer"
          }`}
          key={item.id}
        >
          {item.path ? (
            <Link
              href={item.path}
              className={`un ${
                router.pathname === item.path
                  ? "bg-white active"
                  : "bg-transparent"
              } inline-flex transition-all duration-200 ease items-center text-darkBlue hover:bg-white text-[15px] font-medium py-1.5 relative focus:outline-none outline-none uppercase`}
            >
              {item.label}
              {(item?.columns || item.subMenu) && (
                <span className="text-xs mt-1 xl:mt-0.5 flex justify-end">
                  <div className="w-5 h-5 relative transition duration-300 ease-in-out transform group-hover:-rotate-180">
                    <Image
                      src={"/images/icons/chevron-down.svg"}
                      alt={"email icon"}
                      fill
                      className="object-contain object-left"
                      quality={100}
                      priority={true}
                      sizes="(max-width: 479px) 100px,(max-width: 768px) 100px, (max-width: 1200px) 100px,(max-width: 1920px) 100px, 100px"
                    />
                  </div>
                </span>
              )}
            </Link>
          ) : (
            <div
              className={`${
                router.pathname === item.path ? "bg-white" : "bg-transparent"
              } un inline-flex transition-all duration-200 ease items-center text-darkBlue hover:bg-white text-[15px] font-medium py-1.5 relative focus:outline-none outline-none uppercase gap-2`}
            >
              {item.label}
              {(item?.columns || item.subMenu) && (
                <span className="text-xs mt-1 xl:mt-0.5 flex justify-end">
                  <div className="w-5 h-5 relative transition duration-300 ease-in-out transform group-hover:-rotate-180">
                    <Image
                      src={"/images/icons/chevron-down.svg"}
                      alt={"email icon"}
                      fill
                      className="object-contain object-left"
                      quality={100}
                      priority={true}
                      sizes="(max-width: 479px) 100px,(max-width: 768px) 100px, (max-width: 1200px) 100px,(max-width: 1920px) 100px, 100px"
                    />
                  </div>
                </span>
              )}
            </div>
          )}
          {item?.subMenu && Array.isArray(item.subMenu) && (
            <div className="subMenu bg-white flex flex-col gap-0 = w-[262px] h-auto absolute -left-10 group-hover:opacity-100 rounded-[20px] border-[1px] border-[#D0ECFF] overflow-hidden">
              {item?.subMenu.map((menu, index) => {
                // const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;
                return (
                  <Link
                    href={menu.path}
                    key={"subMenu" + index}
                    target={menu?.external ? "_blank" : "_self"}
                    className={`${
                      router.pathname === menu.path
                        ? "text-darkBlue bg-[#D0ECFF]"
                        : "text-body bg-white"
                    } px-5 flex flex-row hover:text-blue hover:bg-[#C6E4F7] transition-all duration-300 ease items-center gap-4 justify-start w-full text-[15px] leading-tight border-b-[#353535]/10 border-b-[1px] last:border-b-0 py-5`}
                  >
                    {menu.label}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      ))}
    </nav>
  );
}
