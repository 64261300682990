import React from "react";
import cn from "classnames";
import { m } from "framer-motion";
export default function Section({
  children,
  className,
  variant = "normal",
  id,
  ...props
}) {
  return (
    <m.div
      initial={{ opacity: 0, y: 75 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay: 0.15 }}
      id={id}
      className={cn(
        ` w-full h-auto flex flex-col z-0`,
        {
          "lg:py-20 md:py-16 py-16 px-4 md:px-12 lg:px-[72px]":
            variant === "normal",
          "py-6 md:py-10 px-4 md:px-12 lg:px-[72px]": variant === "flat",
          "lg:py-20 md:py-16 py-16 pl-4 md:pl-12 lg:pl-[72px]":
            variant === "carousel",
          "py-0 px-4 md:px-12 lg:px-[72px]": variant === "flatZero",
        },
        className
      )}
      {...props}
    >
      <div className={`w-full flex flex-col`}>{children}</div>
    </m.div>
  );
}
