import * as React from "react";
import Footer from "../footer";
import { Open_Sans } from "next/font/google";
import Header from "../header";

const roboto = Open_Sans({
  weight: ["300", "400", "500", "700"],
  style: ["normal"],
  subsets: ["latin"],
  display: "swap",
  variable: "--font-roboto",
});

const Layout = ({ children }) => {
  return (
    <div
      className={`w-full h-auto block relative bg-white min-h-screen ${roboto.variable} font-roboto`}
    >
      <Header />
      <main className={`w-full`}>{children}</main>
      <Footer />
    </div>
  );
};
export default Layout;
