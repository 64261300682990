import "@/styles/globals.css";
import Layout from "@/components/layout";
import { DefaultSeo } from "next-seo";
import { AnimatePresence, m } from "framer-motion";
import { LazyMotion, domAnimation } from "framer-motion";
import { SingleModalProvider } from "@/contexts";
// import { Partytown } from "@builder.io/partytown/react";
import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
// import Script from "next/script";
export default function App({ Component, pageProps, router }) {
  return (
    <AnimatePresence mode="wait">
      <SingleModalProvider>
        {/* <Partytown debug={true} forward={["dataLayer.push"]} /> */}
        <LazyMotion features={domAnimation}>
          <DefaultSeo
            title="People’s Courage International | Non-Profit Charity Organizations in USA"
            defaultTitle="People’s Courage International | Non-Profit Charity Organizations in USA"
            description="People’s Courage International is a USA-based not-for-profit organization. Join our charity and help us provide critical support to those in need around the world."
            canonical="https://peoplescourageinternational.org"
            openGraph={{
              url: "https://peoplescourageinternational.org",
              title:
                "People’s Courage International | Non-Profit Charity Organizations in USA",
              description:
                "People’s Courage International is a USA-based not-for-profit organization. Join our charity and help us provide critical support to those in need around the world.",
              images: [
                {
                  url: "https://peoplescourageinternational.org/images/pci-logo-512x512.webp",
                  width: 140,
                  height: 140,
                  alt: "People’s Courage International",
                },
              ],
            }}
            twitter={{
              // handle: "@avneesh0612",
              // site: "@avneesh0612",
              cardType: "summary_large_image",
            }}
            additionalLinkTags={[
              {
                rel: "icon",
                href: `/pci-favicon.png`,
              },
              {
                rel: "apple-touch-icon",
                href: "/pci-favicon.png",
                sizes: "76x76",
              },
            ]}
          />
          <Layout>
            <m.div
              key={router.route}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.35 } }}
              exit={{ opacity: 0 }}
              className="h-auto w-full"
            >
              <Component {...pageProps} key={router.route} />

              {/* <GoogleTagManager gtmId="GTM-W2RR5JF" />
              <GoogleAnalytics gaId="G-74BYMVVHM8" />
              <GoogleAnalytics gaId="G-B2B2STS0RF" /> */}
              {/* <Script
                type="text/partytown"
                src="https://www.googletagmanager.com/gtag/js?id=G-74BYMVVHM8"
                strategy="lazyOnload"
                data-partytown
              />
              <script
                type="text/partytown"
                dangerouslySetInnerHTML={{
                  __html: `
            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-74BYMVVHM8', { 
                page_path: window.location.pathname,
            });
        `,
                }}
              /> */}
            </m.div>
          </Layout>
        </LazyMotion>
      </SingleModalProvider>
    </AnimatePresence>
  );
}
