import React, { useEffect, useRef } from "react";
import Logo from "../ui/logo";
import Link from "next/link";
import { m, AnimatePresence } from "framer-motion";
import { useSingleModal } from "@/contexts";
import { siteSettings } from "@/data/site-settings";
import HeaderMenu from "./header-menu";
import Hamburger from "./hamburger";
import MobileMenu from "./mobile-menu";
const { site_header } = siteSettings;
import useScrollLock from "@/utils/use-scroll-lock";
import { useRouter } from "next/router";

export default function Header(props) {
  const router = useRouter();
  const navRef = useRef(null);
  const { setShowNavMenu, showNavMenu, toggleNavMenu } = useSingleModal();
  const toggleNav = () => {
    toggleNavMenu();
  };
  useEffect(() => {
    setShowNavMenu(false);
  }, [router?.pathname]);

  // useScrollLock(showNavMenu);

  return (
    <>
      <header
        className={`sticky top-0 z-[99999] bg-white lg:px-[72px] md:px-12 px-4 w-full flex flex-col justify-around items-center`}
      >
        <div
          className={`flex w-full max-w-[none] items-center transition-all duration-300 ease py-1 lg:py-0`}
        >
          <div className="flex items-center mx-auto w-full h-full relative justify-between">
            <Logo />
            <HeaderMenu
              data={site_header.menu}
              className="hidden ml-auto lg:flex"
              color="text-white"
            />
            <Link
              href="/get-involved"
              className="lg:block hidden hover:scale-105 transition-all duration-300 ease bg-lightBlue hover:bg-darkBlue rounded-full text-white hover:text-white hover:shadow-2xl uppercase font-medium py-2 leading-none px-6 text-[15px] ml-12 z-[100]"
            >
              Get Involved
            </Link>
            <Hamburger />
          </div>
        </div>
        <AnimatePresence mode="wait">
          {showNavMenu && (
            <m.div
              initial={{ height: 0, opacity: 0 }}
              animate={{
                height: showNavMenu ? "auto" : 0,
                opacity: showNavMenu ? 1 : 0,
                transition: {
                  duration: 0.3,
                },
              }}
              exit={{ height: 0, opacity: 0 }}
              ref={navRef}
              className={`fixed md:top-[102px] top-[77px] w-full lg:hidden bg-[#F5F9FC] flex flex-col justify-start items-start overflow-hidden rounded-br-[10px] rounded-bl-[10px]`}
            >
              <MobileMenu isOpen={showNavMenu} />
            </m.div>
          )}
        </AnimatePresence>
      </header>
    </>
  );
}
