import React, { useState, useContext } from "react";

const SingleModalContext = React.createContext();

const useSingleModal = () => {
  const singleModalContext = useContext(SingleModalContext);

  if (!singleModalContext) {
    throw new Error(
      "useSingleModal must be used inside the SingleModalProvider"
    );
  }

  return singleModalContext;
};

const SingleModalProvider = (props) => {
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [showNavMenuMain, setShowNavMenuMain] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const toggleNavMenu = () => {
    setShowNavMenu(!showNavMenu);
  };
  const toggleNavMenuMain = () => {
    setShowNavMenuMain(!showNavMenuMain);
  };
  const value = {
    showNavMenu,
    showNavMenuMain,
    setShowNavMenu,
    setShowNavMenuMain,
    toggleNavMenu,
    toggleNavMenuMain,
    showModal,
    setShowModal,
  };

  return <SingleModalContext.Provider value={value} {...props} />;
};

export { useSingleModal, SingleModalProvider };
