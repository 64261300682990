import React from "react";
import { useSingleModal } from "@/contexts";
export default function Hamburger(props) {
  const genericHamburgerLine = `h-[3px] bg-gradient-to-r from-[#2F9EE3] to-[#004C7B] rounded-xl transition ease transform duration-300`;
  const { toggleNavMenu, showNavMenu } = useSingleModal();
  return (
    <button
      aria-label="hamburger menu"
      className="relative z-20 ml-4 w-[35px] h-[35px] lg:hidden flex flex-col p-2 justify-around items-center group outline-none overflow-hidden focus:outline-none "
      onClick={toggleNavMenu}
    >
      <div
        className={`${genericHamburgerLine} w-full mr-auto ${
          showNavMenu
            ? "rotate-45 translate-y-[6.25px] translate-x-0 opacity-100 group-hover:opacity-100"
            : "opacity-100 group-hover:opacity-100"
        }`}
      />
      <div
        className={`${genericHamburgerLine} w-1/2 ml-auto ${
          showNavMenu
            ? "opacity-0 -translate-x-4"
            : "opacity-100 group-hover:opacity-100"
        } `}
      />
      <div
        className={`${genericHamburgerLine} ${
          showNavMenu
            ? "-rotate-45 -translate-y-[6.75px] -translate-x-0 opacity-100 group-hover:opacity-100 w-full mr-[0%]"
            : "opacity-100 group-hover:opacity-100 w-full"
        } ml-auto`}
      />
    </button>
  );
}
