import React, { useState } from "react";
import { m } from "framer-motion";
import Link from "next/link";
import { useSingleModal } from "@/contexts";
import { useRouter } from "next/router";
import { siteSettings } from "../../data/site-settings";

const { site_header } = siteSettings;

export default function MobileMenu(props) {
  const { setShowNavMenu, showNavMenu } = useSingleModal();
  const router = useRouter();

  const ListMenu = ({ data }) =>
    data.label && (
      <>
        {data.path ? (
          <Link
            href={`${data.path}`}
            onClick={() => setShowNavMenu(false)}
            className={`flex flex-col w-full text-sm text-[#0070B3] font-vietnam text-center border-b-[1px] border-[#C6E4F7] md:py-5 py-3 menu-item uppercase relative transition duration-300 ease-in-out ${
              router.pathname === data.path
                ? "opacity-1 font-semibold"
                : "opacity-80 font-medium"
            }`}
          >
            {`${data.label}`}
            {data?.subMenu && (
              <div className="subMenu bg-white flex flex-col gap-0 px-5 w-[262px] h-auto absolute -left-10 group-hover:opacity-100 rounded-[20px] border-[1px] border-[#D0ECFF] overflow-hidden">
                {data?.subMenu.map((menu, index) => {
                  // const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;
                  return (
                    <Link
                      href={menu.path}
                      key={"subMenu" + index}
                      className={`${
                        router.pathname === menu.path
                          ? "text-blue"
                          : "text-[#353535]"
                      } flex flex-row hover:text-blue transition-all duration-300 ease items-center gap-4 justify-start w-full text-sm leading-tight border-b-[#353535]/10 border-b-[1px] last:border-b-0 py-5`}
                    >
                      {menu.label}
                    </Link>
                  );
                })}
              </div>
            )}
          </Link>
        ) : (
          <div
            className={`w-full text-sm text-[#0070B3] border-b-[1px] border-[#C6E4F7] py-5 font-vietnam menu-item uppercase relative transition duration-300 ease-in-out text-center ${
              router.pathname === data.path
                ? "opacity-1 font-semibold"
                : "opacity-80 font-normal"
            }`}
          >
            {`${data.label}`}
            {data?.subMenu && (
              <div className="flex flex-col items-center justify-center w-full h-auto">
                {data?.subMenu.map((menu, index) => {
                  // const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;
                  return (
                    <Link
                      href={menu.path}
                      key={"subMenu" + index}
                      className={`${
                        router.pathname === menu.path
                          ? "text-blue"
                          : "text-[#353535]"
                      } flex flex-row hover:text-blue w-full gap-2 items-center justify-center text-center transition-all duration-300 ease text-sm leading-tight py-2`}
                    >
                      {menu.label}
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </>
    );
  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 1 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };
  return (
    <>
      <div
        className={`flex flex-col justify-between w-full h-full lg:px-36 md:px-12 px-4`}
      >
        <div className="menu-scrollbar flex-grow h-full">
          <div className="flex flex-col px-0 text-heading h-full">
            <m.div
              variants={variants}
              animate={props.isOpen ? "open" : "closed"}
              className="mobileMenu h-full justify-start items-start px-0 flex flex-col"
            >
              {site_header.menu.map((menu, index) => {
                const dept = 1;
                const menuName = `sidebar-menu-${dept}-${index}`;

                return (
                  <ListMenu
                    dept={dept}
                    data={menu}
                    hasSubMenu={menu.subMenu}
                    menuName={menuName}
                    key={menuName}
                    menuIndex={index}
                    isOpen={showNavMenu}
                    // handleAnchorClick={handleAnchorClick}
                  />
                );
              })}
              <Link
                href="/get-involved"
                className="mb-6 uppercase bg-gradient-to-r from-[#2F9EE3] to-[#004C7B] w-fit px-5 py-1 rounded-[10px] mx-auto mt-6 text-center text-white text-sm font-vietnam"
              >
                Get Involved
              </Link>
            </m.div>
          </div>
        </div>
      </div>
    </>
  );
}
