import React from "react";
import Section from "../ui/section";

export default function index() {
  return (
    <Section className="bg-darkBlue py-3" variant="flatZero">
      <p className="md:text-sm text-xs text-white/90 !text-center w-full leading-tight">
        © 2024 People’s Courage International. All Rights Reserved
      </p>
    </Section>
  );
}
